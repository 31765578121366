<template>
  <div class="musiclist">
    <!-- <h1>歌单详情</h1> -->
    <div
      class="mask"
      :style="{
        'background-image': 'url(' + song.coverImgUrl + ')',
      }"
    ></div>
    <div class="header">
      <h2>{{ song.name }}</h2>
      <div class="bot">
        <img :src="song.coverImgUrl" />
        <p><b>简介：</b>{{ song.description }}</p>
      </div>
      <p class="play iconfont icon-erji">
        <span
          ><i>{{ song.playCount | formatNum }}</i
          >次播放</span
        >
      </p>
    </div>
    <ol class="content">
      <li class="iconfont icon-file-add">
        <span>订阅({{ song.subscribedCount }})</span>
      </li>
      <li class="iconfont icon-comment">
        <span>评论({{ song.commentCount }})</span>
      </li>
      <li class="iconfont icon-share">
        <span>分享({{ song.shareCount }})</span>
      </li>
    </ol>
    <song-lists :tracks="tracks" @play="$emit('play',$event)"></song-lists>
  </div>
</template>
<script>
import SongLists from '@/components/SongLists'
export default {
  props: ["id"],
  data() {
    return {
      song: [],
      tracks: [],
    };
  },
  components:{
    SongLists
  },
  methods: {
    jump(id) {
      console.log("点击跳转id=>", id);
      this.$router.push({ name: "PlayPage", params: { id: id } });
    },
  },
  created() {
    //推荐歌单 || 歌曲详情
    this.$axios.get(`/playlist/detail?id=${this.id}`).then((d) => {
      this.song = d.data.playlist;
      this.tracks = d.data.playlist.tracks;
      //   this.song = this.data.slice(0,6);
      console.log("song", this.song);
      console.log("tracks", this.tracks);
    });
    console.log(this.id);
  },
  filters: {
    formatNum(value) {
      if (value >= 100000000) {
        // return (parseInt(value) / 100000000).toFixed(3) + "亿";
        return (parseInt(value) / 100000000).toFixed(1) + "亿";
      } else if (value >= 100000 && value < 100000000) {
        // return (parseInt(value) / 10000).toFixed(2) + "万";
        return (parseInt(value) / 10000).toFixed(0) + "万";
      } else if (value >= 10000 && value < 100000) {
        // return (parseInt(value) / 10000).toFixed(3) + "万";
        return (parseInt(value) / 10000).toFixed(0) + "万";
      } else {
        return value;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.musiclist {
  position: relative;
  .mask {
    width: 100%;
    height: 200px;
    filter: blur(10px);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .header {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0px;
    left: 20px;
    padding-top: 10px;
    h2 {
      color: #f5f5f5;
      font-size: 20px;
      font-weight: bold;
      height: 26px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-overflow: -o-ellipsis-lastline;
      display: -webkit-box;
      -webkit-line-clamp: 1; //行数需设置
      -webkit-box-orient: vertical;
    }
    .bot {
      display: flex;
      margin-top: 10px;
      img {
        height: 120px;
        border-radius: 20px;
        border-top: 2px solid #f5f5f5;
        box-shadow: 10px 15px 20px rgba(216, 235, 228, 0.5);
      }
      p {
        height: 120px;
        line-height: 25px;
        font-size: 15px;
        padding: 20px 40px 0 30px;
        color: #f3f3f3;
        overflow: hidden;
        text-overflow: ellipsis;
        text-overflow: -o-ellipsis-lastline;
        display: -webkit-box;
        -webkit-line-clamp: 4; //行数需设置
        -webkit-box-orient: vertical;
        b {
          font-size: 18px;
        }
      }
    }
    .play {
      font-size: 25px;
      color: #f3f3f3;
      padding: 5px 0;
      span {
        padding-left: 5px;
        color: #f3f3f3;
        font-size: 14px;
        i {
          font-style: normal;
          font-size: 16px;
        }
      }
    }
  }
  .content {
    width: 90%;
    min-height: 40px;
    line-height: 40px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    background-color: rgba(216, 235, 228, 0.3);
    border-radius: 10px;
    padding: 0 15px;
    margin: 5px auto;
    li {
      font-size: 25px;
      height: 45px;
      overflow: hidden;
      margin: 0 auto;
      span {
        font-weight: normal;
        font-size: 13px;
      }
    }
  }
}
</style>